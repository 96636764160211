import React, { FunctionComponent } from "react"
import { Container, Grid } from "../../common/common"

const ContactUs = () => {
  function sendEmail(e) {
    e.preventDefault()
  }

  return (
    <Container>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={sendEmail}
      >
        <input type="hidden" name="Fale-Conosco" value="contact" />

        <p>
          <input type="text" name="Nome" />
        </p>
        <p>
          <input type="email" name="Email" />
        </p>
        <p>
          <textarea name="message"></textarea>
        </p>
        <p>
          <button type="submit">Send</button>
        </p>
      </form>
    </Container>
  )
}

export default ContactUs
