import React, { FunctionComponent } from "react"
import Layout from "../components/layout/layout"
import ContactUs from "../components/pages/contact-us/contact-us"
import SEO from "../components/seo/seo"

interface PoliticaDePrivacidadeProps {
  location: Location
}

const PoliticaDePrivacidade: FunctionComponent<PoliticaDePrivacidadeProps> = ({
  location,
}) => {
  return (
    <Layout bigHeader={false}>
      <SEO location={location} title="Fale Conosco" type={`WebSite`} />
      <ContactUs></ContactUs>
    </Layout>
  )
}

export default PoliticaDePrivacidade
